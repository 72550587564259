<template>
  <div>
    <v-dialog :fullscreen="fullScreen" v-model="show" v-if="show">
      <v-card elevation="0">
        <v-toolbar
          dark
          color="primary"
          flat
          style="padding-right: 10px !important"
        >
          <v-toolbar-title class="overline"> {{ titleInfo }}</v-toolbar-title>
          <v-btn icon @click="print"><v-icon>mdi-printer</v-icon></v-btn>
          <v-btn icon right absolute dark>
            <v-icon
              @click="fullScreen ? (fullScreen = false) : (fullScreen = true)"
              >{{ setIconScreen() }}</v-icon
            >
            <v-icon @click="show = false">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card elevation="0">
          <div id="print">
            <v-card-text v-html="html"></v-card-text>
          </div>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
export default Vue.extend({
  props: ["html", "visible", "titleInfo"],
  data: () => ({
    fullScreen: false
  }),
  methods: {
    setIconScreen() {
      if (this.fullScreen) return "mdi-fullscreen-exit";
      else return "mdi-fullscreen";
    },
    print() {
      const prtHtml = document.getElementById("print").innerHTML;
      // Get all stylesheets HTML
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );

      WinPrint.document.write(`<!DOCTYPE html>
        <html>
          <head>
              <title> ${this.titleInfo}</title>
            ${stylesHtml}
          </head>
          <body>
            ${prtHtml}
          </body>
        </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
});
</script>
<style scoped>
fieldset {
  width: 97%;
  height: 90%;
  margin: auto;
  border-radius: 4px;
  padding: 15px;
}
</style>
